import { Button } from "@material-ui/core";
import { AddOutlined, PlusOne } from "@material-ui/icons";
import React from "react";

const MOButtonV2 = (props) => {
  const {
    onClick = () => {},
    text = "Button",
    IconType,
    disabled = false,
    type = "button",
    radius = 20,
    backgroundColor = "transparent",
    border = "",
    textColor = "#000000",
    padding = "12px 20px 12px 18px",
    height = 40,
    boxShadow = "none",
    widthSize = "none",
    fontSize = 16,
  } = props;
  return (
    <Button
      disabled={disabled}
      type={type}
      style={{
        borderRadius: radius,
        backgroundColor: backgroundColor,
        color: textColor,
        height: height,
        textTransform: "none",
        border: border,
        fontSize: fontSize,
        padding: padding,
        boxShadow: boxShadow,
        width: widthSize,
        minWidth: widthSize,
      }}
      className="text-no-warp mo2-button "
      variant="contained"
      onClick={onClick}
    >
      {IconType && <IconType />}

      {text}
    </Button>
  );
};

export default MOButtonV2;
