import React, { useEffect } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { AccountIcon, CardIcon, CashIcon } from "../../assets/icons";

export default function ToggleButtons({
  refresh,
  onClick,
  defaultValue = "left",
}) {
  const [alignment, setAlignment] = React.useState(defaultValue);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  useEffect(() => {
    setAlignment(defaultValue);
  }, [refresh]);

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      style={{ height: 40, borderRadius: 8 }}
    >
      <ToggleButton
        value="left"
        style={{ backgroundColor: alignment == "left" && "#29AEE6" }}
        aria-label="left aligned"
        onClick={(value) => {
          onClick("CASH");
        }}
      >
        {/* <LocalAtmIcon /> */}
        <CashIcon />
      </ToggleButton>
      <ToggleButton
        value="center"
        aria-label="centered"
        style={{ backgroundColor: alignment == "center" && "#29AEE6" }}
        onClick={(value) => {
          onClick("CARD");
        }}
      >
        {/* <CreditCardIcon /> */}
        <CardIcon />
      </ToggleButton>

      <ToggleButton
        value="right"
        aria-label="right aligned"
        style={{ backgroundColor: alignment == "right" && "#29AEE6" }}
        onClick={(value) => {
          onClick("ACCOUNT");
        }}
      >
        {/* <AccountBalanceWalletOutlinedIcon /> */}
        <AccountIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
