import { useEffect, useState } from "react";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";

const RegexBuilder = ({ value, onChange }) => {
  const [blocks, setBlocks] = useState([
    { type: "alphabets", min: 1, max: 10, customPattern: "", error: "" },
  ]);
  const [examples, setExamples] = useState([]);

  // Handle block changes with validation
  const handleBlockChange = (index, field, value) => {
    const newBlocks = [...blocks];
    const block = newBlocks[index];

    if (field === "min" || field === "max") {
      const numValue = Number(value);
      if (field === "min" && numValue > block.max) {
        block.error = "Min cannot be greater than Max.";
      } else if (field === "max" && numValue < block.min) {
        block.error = "Max cannot be less than Min.";
      } else {
        block.error = "";
      }
      block[field] = numValue;
    } else {
      block[field] = value;
    }

    setBlocks(newBlocks);
  };

  useEffect(() => {
    const reg = generateRegex();
    onChange?.(reg);
  }, [blocks]);

  // Add new block
  const addBlock = () => {
    setBlocks([
      ...blocks,
      { type: "alphabets", min: 1, max: 10, customPattern: "", error: "" },
    ]);
  };

  // Generate regex
  const generateRegex = () => {
    const regexParts = blocks.map(({ type, min, max, customPattern }) => {
      let pattern = "";
      switch (type) {
        case "alphabets":
          pattern = "[a-zA-Z]";
          return `${pattern}{${min},${max}}`;
        case "alphabets-uppercase":
          pattern = "[A-Z]";
          return `${pattern}{${min},${max}}`;
        case "alphabets-lowercase":
          pattern = "[a-z]";
          return `${pattern}{${min},${max}}`;
        case "numbers":
          pattern = "\\d";
          return `${pattern}{${min},${max}}`;
        case "alphanumeric":
          pattern = "[a-zA-Z0-9]";
          return `${pattern}{${min},${max}}`;
        case "alphanumeric-lowercase":
          pattern = "[a-z0-9]";
          return `${pattern}{${min},${max}}`;
        case "alphanumeric-uppercase":
          pattern = "[A-Z0-9]";
          return `${pattern}{${min},${max}}`;
        case "special":
          pattern = '[!@#$%^&*(),.?":{}|<>]';
          return `${pattern}{${min},${max}}`;
        case "custom":
          pattern = customPattern;
          return `${pattern}`;
        default:
          break;
      }
      return `${pattern}{${min},${max}}`;
    });

    const regexPatternFinal = `^${regexParts.join("")}$`;
    return regexPatternFinal;
  };

  // Generate example strings based on the regex
  const generateExamples = () => {
    const examplesList = [];

    for (let i = 0; i < 5; i++) {
      let result = "";
      for (const block of blocks) {
        let blockPattern = "";
        const { type, min = 0, max = 0, customPattern } = block;

        switch (type) {
          case "alphabets":
            blockPattern =
              "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            break;
          case "alphabets-uppercase":
            blockPattern = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            break;
          case "alphabets-lowercase":
            blockPattern = "abcdefghijklmnopqrstuvwxyz";
            break;
          case "numbers":
            blockPattern = "0123456789";
            break;
          case "alphanumeric":
            blockPattern =
              "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            break;
          case "alphanumeric-lowercase":
            blockPattern = "abcdefghijklmnopqrstuvwxyz0123456789";
            break;
          case "alphanumeric-uppercase":
            blockPattern = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            break;
          case "special":
            blockPattern = '!@#$%^&*(),.?":{}|<>';
            break;
          case "custom":
            blockPattern = customPattern;
            break;
          default:
            blockPattern = ".";
        }

        // Generate random string for the block
        const blockLength =
          min === max ? min : Math.floor(Math.random() * (max - min + 1)) + min;
        for (let j = 0; j < blockLength; j++) {
          result += blockPattern.charAt(
            Math.floor(Math.random() * blockPattern.length)
          );
        }
      }

      examplesList.push(result);
    }

    setExamples(examplesList);
  };

  // Render
  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6 network-module-fields">
      {/* Render Blocks */}
      {blocks.map((block, index) => (
        <div
          key={index}
          className="flex flex-col gap-2 p-4 bg-gray-100 rounded border"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "8px",
              marginTop: "10px",
            }}
          >
            {/* Select or Custom Input */}
            {block.type === "custom" ? (
              <input
                type="text"
                value={block.customPattern}
                onChange={(e) => {
                  handleBlockChange(index, "customPattern", e.target.value);
                  handleBlockChange(index, "min", e.target.value.length);
                  handleBlockChange(index, "max", e.target.value.length);
                }}
                placeholder="Enter custom pattern"
              />
            ) : (
              <>
                <select
                  value={block.type}
                  onChange={(e) =>
                    handleBlockChange(index, "type", e.target.value)
                  }
                  className="w-full"
                >
                  <option value="alphabets">Alphabets (A-z)</option>
                  <option value="alphabets-uppercase">
                    Uppercase Alphabets (A-Z)
                  </option>
                  <option value="alphabets-lowercase">
                    Lowercase Alphabets (a-z)
                  </option>
                  <option value="numbers">Numbers (0-9)</option>
                  <option value="alphanumeric">Alphanumeric</option>
                  <option value="alphanumeric-lowercase">
                    Alphanumeric (Lowercase)
                  </option>
                  <option value="alphanumeric-uppercase">
                    Alphanumeric (Uppercase)
                  </option>
                  <option value="special">Special Characters</option>
                  <option value="custom">Custom</option>
                </select>
                <input
                  type="number"
                  value={block.min}
                  max={block.max}
                  onChange={(e) =>
                    handleBlockChange(index, "min", e.target.value)
                  }
                  placeholder="Min"
                />

                {/* Max Input */}
                <input
                  type="number"
                  value={block.max}
                  min={block.min}
                  onChange={(e) =>
                    handleBlockChange(index, "max", e.target.value)
                  }
                  placeholder="Max"
                />
              </>
            )}

            {/* Min Input */}

            {/* Remove Button */}
            {blocks.length > 1 && (
              <MOButtonV2
                text="-"
                textColor="#ffffff"
                radius={4}
                height={32}
                padding={"10px 20px"}
                backgroundColor="#FF0000"
                fontSize={11}
                widthSize={"max-content"}
                onClick={() => setBlocks(blocks.filter((_, i) => i !== index))}
              />
            )}
          </div>

          {/* Error Message */}
          {block.error && <p className="text-danger font-10">{block.error}</p>}
        </div>
      ))}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "8px",
          marginTop: "10px",
        }}
      >
        <MOButtonV2
          text="Add Block"
          textColor="#ffffff"
          radius={4}
          height={32}
          padding={"10px 12px"}
          backgroundColor="#29AEE6"
          fontSize={11}
          onClick={addBlock}
        />

        {/* Generate Examples Button */}
        <MOButtonV2
          text="Generate Examples"
          textColor="#ffffff"
          radius={4}
          height={32}
          padding={"10px 12px"}
          backgroundColor="#29AEE6"
          fontSize={11}
          onClick={generateExamples}
        />
      </div>

      {/* Generated Examples */}
      {examples.length > 0 && (
        <div style={{ margin: "10px 0px" }}>
          <h3>Generated Examples</h3>
          <div>
            {examples.map((example, index) => (
              <p key={index} className="text-gray-700">
                {example}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegexBuilder;
