import { connect } from "react-redux";
import { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  Typography,
  Drawer,
  Box,
  Slider,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Bookings from "../../../redux/actions/local/bookings";
import ThunksDispatchers from "../../../redux/actions/thunks/dispatchers";
import ThunksBookings from "../../../redux/actions/thunks/bookings";
import ExclusiveSelect from "../../../components/commons/ExclusiveSelect";
import CarExclusiveSelect from "../../../components/commons/CarExclusiveSelect";
import DatePicker from "../../../components/commons/DatePicker";
import LocationField from "../../../components/commons/LocationField";
import Input from "../../../components/commons/Input";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import * as momentTimeZone from "moment-timezone";
import MOButton from "../../../components/commons/MOButton";
import {
  FlightPlaneIcon,
  LuggageIcon,
  PersonIcon,
  SignalPassengerIcon,
} from "../../../assets/icons";
import { ChatBubbleOutlineOutlined } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme) => ({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
    width: 596,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },

  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    // marginBottom: 20,
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
  heading: {
    fontSize: 20,
    fontWeight: 500,
    color: "#000000",
  },
  headingDetail: {
    fontSize: 12,
    // fontWeight:500,
    color: "#787486",
  },
}));

function UpdateBookingDialog({
  bookingState,
  open,
  onClose,
  dispatchers,
  getDispatchers,
  updateBooking,
  loading,
  status,
  role,
}) {
  const classes = useStyles();
  const [noteOpen, setNoteOpen] = useState(false);
  const [data, setData] = useState({
    dateTime: bookingState.dateTime,
    paymentType: bookingState.paymentType,
    // dispatcherId: bookingState.dispatcherId || "",
    nameCaller: bookingState.nameCaller,
    pickup: bookingState.pickup,
    destination: bookingState.destination,
    fare: bookingState?.finalPrice || null,
    guestName: bookingState.guestName,
    guestPhoneNumber: bookingState.guestPhoneNumber,
    flightNumber: bookingState.flightNumber,
    noOfPeople: bookingState.noOfPeople,
    carType: bookingState.carType,
    timeZone: bookingState.timeZone,
    newTimeZone: undefined,
    clientId: bookingState.clientId,
    noOfLuggage: bookingState.noOfLuggage,
  });

  const updateField = (field, value) => {
    setData({ ...data, [field]: value });
  };

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <div className={classes.dialogPaper}>
          <Grid
            container
            direction="column"
            // justifyContent="center"
            // alignItems="center"
          >
            <Typography className={classes.heading}>Update Booking</Typography>
            <Typography className={classes.headingDetail}>
              Provide the information to finalise your car reservation
            </Typography>
          </Grid>

          <Grid style={{ marginTop: 16 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              // alignItems="center"
              className={classes.ExclusiveSelect}
            >
              <DatePicker
                date={
                  data.dateTime !== ""
                    ? // new Date(momentTimeZone(data?.dateTime).tz(data?.timeZone).format("YYYY-MM-DD HH:mm"))
                      new Date(data.dateTime).toLocaleString("en-US", {
                        timeZone: data.newTimeZone || data.timeZone,
                      })
                    : new Date()
                }
                onChange={(date) =>
                  setData({
                    ...data,
                    dateTime: date,
                    newTimeZone: momentTimeZone.tz.guess(),
                  })
                }
              />
              <MOButton title="Now" icon={false} />
            </Grid>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <PersonIcon />
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 400,
                  marginLeft: 3,
                  marginRight: 6,
                }}
              >
                {data?.noOfPeople}
              </Typography>
              <Slider
                size="small"
                defaultValue={1}
                step={1}
                min={1}
                max={16}
                value={data.noOfPeople}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={(e, newValue) => {
                  updateField("noOfPeople", newValue);
                }}
              />
              <ChatBubbleOutlineOutlined
                style={{ marginRight: 62, marginLeft: 6 }}
                onClick={() => {
                  setNoteOpen(!noteOpen);
                }}
              />
            </Box>

            <Box style={{ display: "flex" }}>
              <ExclusiveSelect
                value={data.paymentType}
                onClick={(value) => updateField("paymentType", value)}
                defaultValue={
                  data?.paymentType == "ACCOUNT"
                    ? "right"
                    : data?.paymentType == "CARD"
                    ? "center"
                    : "left"
                }
              />
              <Box style={{ width: "100%", marginLeft: "12px" }}>
                {noteOpen && (
                  <Input
                    fullWidth
                    placeholder="Note"
                    size="small"
                    multiline
                    value={bookingState.note}
                    className={classes.textfield_side}
                    onChange={(e) => updateField("note", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ChatBubbleOutlineOutlined
                            style={{ color: "#AAAAAA" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            </Box>

            <Divider light style={{ marginTop: 22, marginBottom: 12 }} />

            {role !== "Customer" && role !== "CustomerUser" && (
              <>
                {bookingState?.client?.firstName && (
                  <Typography style={{ marginTop: 10 }}>
                    Client Name:
                    {" " +
                      bookingState?.client?.firstName +
                      " " +
                      bookingState?.client?.lastName}
                  </Typography>
                  // Dispatcher in edit and duplicate booking in web should be removed
                  //   <Input
                  //   value={data.dispatcherId}
                  //   onChange={(e) =>
                  //     updateField("dispatcherId", e.target.value)
                  //   }
                  //   select
                  //   variant={"outlined"}
                  //   label="Dispatchers"
                  //   size="small"
                  //   fullWidth
                  //   className={classes.textfield_upper}
                  // >
                  //   <MenuItem key={"none"} value={""}>
                  //     {"None"}
                  //   </MenuItem>
                  //   {!loading &&
                  //     dispatchers?.map((item, index) => {
                  //       const key = item?.User?.id || item?.id;
                  //       const value = item?.User?.id || item?.id;
                  //       const displayName = item?.User?.displayName || item?.displayName;

                  //       return (
                  //         <MenuItem key={key} value={value}>
                  //           {displayName}
                  //         </MenuItem>
                  //       );
                  //     })}
                  // </Input>
                )}
              </>
            )}
            {/* <Input
              label="Name Caller"
              size="small"
              fullWidth
              value={data.nameCaller}
              className={classes.textfield_upper}
              onChange={(e) => updateField("nameCaller", e.target.value)}
            /> */}

            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item xs={12}>
                <Input
                  fullWidth
                  placeholder="Passenger Name"
                  size="small"
                  value={data.guestName}
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("guestName", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SignalPassengerIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiPhoneNumber
                  fullWidth
                  variant="outlined"
                  placeholder="Passenger Phone Number"
                  defaultCountry={"nl"}
                  className={classes.textfield_lower}
                  size="small"
                  value={data.guestPhoneNumber}
                  onChange={(phoneNumber) => {
                    updateField("guestPhoneNumber", phoneNumber);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider light style={{ marginTop: 1 }} />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  placeholder="Flight Number "
                  size="small"
                  value={data.flightNumber}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("flightNumber", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FlightPlaneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {role !== "Customer" && role !== "CustomerUser" ? (
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Fare"
                    size="small"
                    value={data.fare}
                    className={classes.textfield_side}
                    onChange={(e) => updateField("fare", e.target.value)}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={role !== "Customer" && role !== "CustomerUser" ? 12 : 6}
              >
                <Input
                  select
                  value={data.noOfLuggage}
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("noOfLuggage", e.target.value)}
                  placeholder="Number Of Luggage "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LuggageIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                    (item, index) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Input>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.MultiSelect}>
            <CarExclusiveSelect
              value={data.carType}
              noOfPeople={data.noOfPeople}
              onClick={(value) => updateField("carType", value)}
            />
          </Grid>
          {/* locations */}
          <LocationField
            location={data.pickup}
            placeholder={"Pickup"}
            onChange={(value) => {
              if (value) {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) =>
                    updateField("pickup", {
                      name: value,
                      coordinates: [lat, lng],
                    })
                  );
              }
            }}
          />
          <LocationField
            location={data.destination}
            placeholder={"Destination"}
            onChange={(value) => {
              if (value) {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) =>
                    updateField("destination", {
                      name: value,
                      coordinates: [lat, lng],
                    })
                  );
              }
            }}
          />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ marginTop: 16 }}
          >
            <Box style={{ marginRight: 15 }}>
              <MOButton
                title="Cancel"
                icon={false}
                onClick={onClose}
                type="secondary"
              />
            </Box>
            <MOButton
              title="Update"
              icon={false}
              disabled={
                !data.guestPhoneNumber ||
                !data.dateTime ||
                !data.destination ||
                !data.noOfPeople ||
                !data.pickup
              }
              onClick={async () => {
                delete data.newTimeZone;
                await updateBooking(bookingState.id, data);
                onClose();
              }}
            />
            {/* <Button
              style={{
                borderRadius: 6,
                backgroundColor: "primary",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              variant="contained"
              
            >
              Cancel
            </Button> */}
            {/* <Button
              style={{
                borderRadius: 6,
                backgroundColor: "#2AABD5",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              disabled={
                !data.guestPhoneNumber ||
                !data.dateTime ||
                !data.destination ||
                !data.noOfPeople ||
                !data.pickup
              }
              variant="contained"
              onClick={() => {
                delete data.newTimeZone;
                updateBooking(bookingState.id, data);
                // onClose();
              }}
            >
              Update
            </Button> */}
          </Grid>
        </div>
      </Drawer>
    </>
  );
}
const mapState = (state) => ({
  loading: state.dispatchers.loading,
  status: state,
});

const mapDispatch = {
  updateField: Bookings.field.update,
  updateBooking: ThunksBookings.UpdateBooking,
};

export default connect(mapState, mapDispatch)(UpdateBookingDialog);
