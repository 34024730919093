import Local from "../../local";
import _, { pick } from "lodash";
import moment from "moment";
import { GetInfoFromFlightaware, GetTimeZone, getTimeZoneTime } from "../../../../helpers";
import * as momentTimeZone from 'moment-timezone'
import { toastMessage } from "../../../../helpers/toastMessage";
const GetNextMonday = (day: String, dateTime: any) => {
  let nextMonday;
  if (day !== "Monday") {
    if (day == "Tuesday")
      nextMonday = moment(dateTime).add(6, "days").format("YYYY-MM-DD");
    if (day == "Wednesday")
      nextMonday = moment(dateTime).add(5, "days").format("YYYY-MM-DD");
    if (day == "Thursday")
      nextMonday = moment(dateTime).add(4, "days").format("YYYY-MM-DD");
    if (day == "Friday")
      nextMonday = moment(dateTime).add(3, "days").format("YYYY-MM-DD");
    if (day == "Saturday")
      nextMonday = moment(dateTime).add(2, "days").format("YYYY-MM-DD");
    if (day == "Sunday")
      nextMonday = moment(dateTime).add(1, "days").format("YYYY-MM-DD");
  }
  return nextMonday;
};

export type ThunksBookingsListing = {
  BookingsListing: (
    type: string,
    page: any,
    limit: any,
    searchText: any
  ) => any;
  CreateBooking: () => any;
  CreateAdditionalBooking: (
    bookingReference: any,
    from: any,
    to: any,
    driver: any,
    originClientId: any
  ) => any;
  UpdateBooking: (id: any, obj: any) => any;
  CancelBooking: (id: string, role: string, userId: string) => any;
  CancelPendingBooking: (id: string) => any;
  DuplicateBooking: (id: any, obj: any) => any;
  CustomerBookings: (type: string, offset: any, limit: any) => any;
  CreateCustomerBooking: () => any;
  DuplicateCustomerBooking: (obj: any) => any;
  BookingIntegration: (id: any, clientId: any, clientSecret: any) => any;
  RemoveBookingIntegration: (id: any, type: any) => any;
  getBookingIntegrationDispatchers: () => any;
  updateCommissionRoundable: (commission: any, roundable: boolean) => any;
  GetDriverEventsBybooking: (id: any) => any;
  GetBookingdriver: (id: any) => any;
  GetPayouts: (orgId: any, range: string, zoneOffset: any) => any;
  GetSinglePayout: (
    orgId: any,
    range: string,
    driverId: string,
    zoneOffset: any
  ) => any;
  SaveAdditionalPayment: (objects: any) => any;
  UpdateAdditionalPayment: (objects: any) => any;
  SaveAdditionalPayin: (objects: any) => any;
  UpdateAdditionalPayin: (objects: any) => any;
  UpdateBookingOrg: (id: any, obj: any) => any;
  UpdateAdditionalBookingOrg: (id: any, obj: any) => any;
  DeleteAdditionalBookingOrg: (id: any, obj: any) => any;
  GetAssignedPaginated: (
    type: string,
    page: any,
    limit: any,
    searchText: any
  ) => any;
  getTotalAndAssignedRides: () => any;
  GetPayins: (orgId: any, range: string) => any;
  GetSinglePayin: (orgId: any, range: string, driverId: string) => any;
};

const ThunksBooking: ThunksBookingsListing = {
  GetSinglePayin: (orgId: any, range: string, clientId: string) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);

    return api
      .getSinglePayin(orgId, range, clientId)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error)
        const {
          response: { data },
        } = error;

        dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
      });
  },
  GetPayins: (orgId: any, range: string) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);

    return api
      .getPayins(orgId, range)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error)
        const {
          response: { data },
        } = error;
        dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
      });
  },
  DeleteAdditionalBookingOrg:
    (id: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .deleteAdditionalBookingOrg(id)
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  UpdateAdditionalBookingOrg:
    (id: any, obj: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .updateAdditionalBookingOrg(id, obj)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  UpdateBookingOrg:
    (id: any, obj: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .updateBookingOrg(id, obj)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  UpdateAdditionalPayment:
    (objects: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .updateAdditionalPayment(objects)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  SaveAdditionalPayin:
    (objects: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .saveAdditionalPayin(objects)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  UpdateAdditionalPayin:
    (objects: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .updateAdditionalPayin(objects)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  SaveAdditionalPayment:
    (objects: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .saveAdditionalPayment(objects)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error);
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  GetSinglePayout:
    (orgId: any, range: string, driverId: string, zoneOffset: any) =>
      (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
        } = getState();

        const api = mobioApi(token);
        return api
          .getSinglePayout(orgId, range, driverId, zoneOffset)
          .then((response: any) => {
            return response.data;
          })
          .catch((error: any) => {
            console.log(error);
            const {
              response: { data },
            } = error;

            dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
          });
      },
  GetPayouts:
    (orgId: any, range: string, zoneOffset: any) =>
      (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
        } = getState();

        const api = mobioApi(token);
        return api
          .getPayouts(orgId, range, zoneOffset)
          .then((response: any) => {
            return response.data;
          })
          .catch((error: any) => {
            console.log(error);
            const {
              response: { data },
            } = error;

            dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
          });
      },
  GetBookingdriver:
    (id: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .getBookingdriver(id)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  GetDriverEventsBybooking:
    (id: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);
      return api
        .getDriverEventsBybooking(id)
        .then((response: any) => {
          return response.data.reverse();
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;

          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        });
    },
  updateCommissionRoundable:
    (commission, roundable) =>
      (dispatch: any, getState: any, mobioApi: any) => {
        if (commission !== "") {
          const {
            userInfo: { token },
          } = getState();
          const api = mobioApi(token);
          return api
            .updateCommissionRoundable({ commission, roundable })
            .then((response: any) => {
              alert("Commission is updated successfully.");
            })
            .catch((error: any) => {
              if (error.code === "ECONNABORTED") {
                dispatch(
                  Local.BookingsListing.api.error(
                    "Request Failed",
                    "Something went wrong. Please try again."
                  )
                );
                alert("Something went wrong. Please try again.");
              } else {
                const {
                  response: { data },
                } = error;
                alert(data.msg);
                dispatch(
                  Local.BookingsListing.api.error("Request Failed", data.msg)
                );
              }
            });
        } else {
          alert("Commission needs to be a an integer or decimal point!");
        }
      },
  GetAssignedPaginated:
    (type, page, limit, searchText) =>
      (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
        } = getState();
        const api = mobioApi(token);
        dispatch(Local.BookingsListing.api.request());
        return api
          .getAssignedPaginated(type, page, limit, searchText)
          .then(async (response: any) => {
            let responseData = await GetInfoFromFlightaware(
              response.data.booking
            );

            const data = _.chain(responseData)
              .groupBy((record) => moment(record.dateTime).format("YYYY-MM-DD"))
              .map((value, key) => ({
                date: key,
                data: value,
              }))
              .value();
            console.log('booiing data', data);

            dispatch(Local.BookingsListing.api.response(data));
            return response.data.total;
          })
          .catch((error: any) => {
            if (error.code === "ECONNABORTED") {
              dispatch(
                Local.BookingsListing.api.error(
                  "Request Failed",
                  "Something went wrong. Please try again."
                )
              );
            } else {
              const {
                response: { data },
              } = error;
              dispatch(
                Local.BookingsListing.api.error("Request Failed", data.msg)
              );
            }
          });
      },
  BookingsListing: (type, page, limit, searchText) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.BookingsListing.api.request());
    return api
      .getTypeBookings(type, page, limit, searchText)
      .then(async (response: any) => {

        dispatch(Local.Sheet.api.response(response.data.spreadSheet))

        let responseData = await GetInfoFromFlightaware(response.data.booking);
        if (type == "history") {
          responseData = response.data.booking;
        }
        const data = _.chain(responseData)
          .groupBy((record) => moment(record.dateTime).format("YYYY-MM-DD"))
          .map((value, key) => ({
            date: key,
            data: value,
          }))
          .value();
        dispatch(
          Local.BookingsListing.api.response(type == "history" ? data : data)
        );
        return response.data.total;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.BookingsListing.api.error(
              "Request Failed",
              "Something went wrong. Please try again."
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.BookingsListing.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  getBookingIntegrationDispatchers:
    () => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api.getIntegrationDispatchers().then((res: any) => {
        alert(JSON.stringify(res.data.bookingIntegration));
      });
    },
  BookingIntegration:
    (id: any, obj: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      dispatch(Local.BookingIntegration.api.request());
      return api
        .bookingIntegration(id, obj)
        .then((response: any) => {
          const { data } = response;
          dispatch(
            Local.BookingIntegration.api.response(
              obj.type === 'WorldTransfer.com' ? "WorldTransfer.com Integrated successfully" : "Booking.com Integrated successfully"
            )
          );
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.BookingIntegration.api.error(
              "Request Failed",
              "Failed to Integrate"
            )
          );
        });
    },
  RemoveBookingIntegration:
    (id: any, type: any) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      dispatch(Local.BookingIntegration.api.request());
      return api
        .removeBookingIntegration(id, type)
        .then((response: any) => {
          const { data } = response;
          dispatch(
            Local.BookingIntegration.api.response(
              "Integration is removed Successfully"
            )
          );
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          Local.BookingIntegration.api.error(
            "Request Failed",
            "Unablde to remove Integration"
          );
        });
    },
  CustomerBookings:
    (type, offset, limit, searchText = "") =>
      (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
        } = getState();
        const api = mobioApi(token);
        dispatch(Local.BookingsListing.api.request());

        return api
          .getCustomerBookings(type, offset, limit, searchText)
          .then((response: any) => {
            const { data } = response;
            dispatch(Local.BookingsListing.api.response(data));
            console.log("this is from thunk from customer", response);
          })
          .catch((error: any) => {
            const {
              response: { data },
            } = error;
            dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
          });
      },
  CreateCustomerBooking:
    () => async (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
        bookings: {
          state: {
            pickup,
            destination,
            dateTime,
            noOfPeople,
            carType,
            fare,
            guestName,
            guestPhoneNumber,
            flightNumber,
            nameCaller,
            paymentType,
            noOfLuggage,
          },
        },
      } = getState();
      console.log("this is booking from customer", pickup);
      const timeZone = await GetTimeZone(pickup.coordinates);
      const newDateTime = getTimeZoneTime(dateTime, timeZone.timeZoneId)
      const finalDate = new Date(newDateTime).toISOString()
      console.log("final date", finalDate, timeZone.timeZoneId);

      const api = mobioApi(token);
      dispatch(Local.CreateBooking.api.request());

      return api
        .createCustomerBooking({
          pickup: {
            coordinates: pickup.coordinates,
            name: pickup.name.label,
          },
          destination: {
            coordinates: destination.coordinates,
            name: destination.name.label,
          },
          dateTime: finalDate,
          noOfPeople,
          carType,
          fare: fare != "" ? fare : null,
          guestName,
          guestPhoneNumber,
          flightNumber,
          nameCaller,
          paymentType,
          timeZone: timeZone.timeZoneId,
          noOfLuggage
        })
        .then((response: any) => {
          dispatch(Local.BookingsListing.api.request());
          dispatch(Local.CreateBooking.api.response({}));
          dispatch(Local.CreateBooking.resetAll());

          // alert("Create Booking successful for customer");
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.CreateBooking.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(Local.CreateBooking.api.error("Request Failed", data.msg));
          }
        });
    },
  DuplicateCustomerBooking:
    (obj) => async (dispatch: any, getState: any, mobioApi: any) => {

      const {
        userInfo: { token },
      } = getState();
      console.log("this is booking from customer", getState());

      const api = mobioApi(token);
      let destination = "";
      let pickUp = "";
      if (typeof obj.pickup.name == "object") {
        pickUp = obj.pickup.name.label;
      } else {
        pickUp = obj.pickup.name;
      }
      if (typeof obj.destination.name == "object") {
        destination = obj.destination.name.label;
      } else {
        destination = obj.destination.name;
      }
      let finalDate;
      let timeZone;
      if (typeof obj.dateTime == 'object' && typeof obj.pickup.name == "object") {
        timeZone = await GetTimeZone(obj?.pickup?.coordinates);
        const newDateTime = getTimeZoneTime(obj.dateTime, timeZone.timeZoneId)
        finalDate = new Date(newDateTime).toISOString()
      } else if (typeof obj.dateTime == 'object') {
        const newDateTime = getTimeZoneTime(obj.dateTime, obj.timeZone)
        finalDate = new Date(newDateTime).toISOString()
        timeZone = obj.timeZone
      } else if (typeof obj.pickup.name == "object") {
        timeZone = await GetTimeZone(obj?.pickup?.coordinates);
        const prevLocalTime = moment(obj.dateTime).tz(obj.timeZone);
        const conversion = prevLocalTime.clone().tz(timeZone.timeZoneId, true);
        const formatted = conversion.format();
        finalDate = new Date(formatted).toISOString()
      } else {
        finalDate = obj.dateTime;
        timeZone = obj.timeZone;
      }

      dispatch(Local.CreateBooking.api.request());

      return api
        .createCustomerBooking({
          pickup: {
            coordinates: obj.pickup.coordinates,
            name: pickUp,
          },
          destination: {
            coordinates: obj.destination.coordinates,
            name: destination,
          },
          dateTime: finalDate,
          noOfPeople: obj.noOfPeople,
          carType: obj.carType,
          fare: obj.fare,
          guestName: obj.guestName,
          guestPhoneNumber: obj.guestPhoneNumber,
          flightNumbrt: obj.flightNumber,
          nameCaller: obj.nameCaller,
          paymentType: obj.paymentType,
          timeZone: timeZone.timeZoneId,
          noOfLuggage: obj.noOfLuggage
        })
        .then((response: any) => {
          dispatch(Local.BookingsListing.api.request());
          dispatch(Local.CreateBooking.api.response({}));
          dispatch(Local.CreateBooking.resetAll());

          // alert("Create Booking successful for customer");
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.CreateBooking.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(Local.CreateBooking.api.error("Request Failed", data.msg));
          }
        });
    },
  CreateAdditionalBooking:
    (bookingReference, from, to, driver, originClientId = null) =>
      (dispatch: any, getState: any, mobioApi: any) => {
        const {
          userInfo: { token },
          bookings: {
            state: {
              pickup,
              destination,
              dateTime,
              noOfPeople,
              carType,
              fare,
              clientId,
              guestName,
              guestPhoneNumber,
              flightNumber,
              nameCaller,
              paymentType,
            },
          },
        } = getState();

        const api = mobioApi(token);
        dispatch(Local.CreateBooking.api.request());

        return api
          .createAdditionalBooking({
            pickup: {
              coordinates: pickup.coordinates,
              name: pickup.name.label,
            },
            destination: {
              coordinates: destination.coordinates,
              name: destination.name.label,
            },
            dateTime,
            noOfPeople,
            carType,
            fare: fare == "" ? 0 : fare,
            clientId,
            guestName,
            guestPhoneNumber,
            flightNumber,
            nameCaller,
            paymentType,
            bookingReference,
            driverId: driver,
            weekStart: from,
            weekEnd: to,
            originClientId: originClientId
          })
          .then((response: any) => {
            toastMessage("success",<><section>
              <h1 className="font-14 font-600">Additional Booking Create Successfully</h1>
              <p className="font-12"><strong>RefId:</strong> {response?.data?.refId}</p>
              </section></>)
            dispatch(Local.BookingsListing.api.request());
            dispatch(Local.CreateBooking.api.response({}));
            dispatch(Local.CreateBooking.resetAll());
            dispatch(Local.CreateBooking.api.reset());
            return response.data;
          })
          .catch((error: any) => {
            if (error.code === "ECONNABORTED") {
              dispatch(
                Local.BookingsListing.api.error(
                  "Request Failed",
                  "The API request timed out. Please Refresh the page"
                )
              );
            } else {
              const {
                response: { data },
              } = error;
              alert(data.msg);
              dispatch(
                Local.BookingsListing.api.error("Request Failed", data.msg)
              );
            }
          });
      },

  CreateBooking: () => async (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
      bookings: {
        state: {
          pickup,
          destination,
          dateTime,
          noOfPeople,
          carType,
          fare,
          clientId,
          guestName,
          guestPhoneNumber,
          flightNumber,
          nameCaller,
          paymentType,
          bookingReference
        },
      },
    } = getState();

    const api = mobioApi(token);
    dispatch(Local.CreateBooking.api.request());
    const timeZone = await GetTimeZone(pickup.coordinates);
    const newDateTime = getTimeZoneTime(dateTime, timeZone.timeZoneId)
    const finalDate = new Date(newDateTime).toISOString()

    return api
      .createBooking({
        pickup: {
          coordinates: pickup.coordinates,
          name: pickup.name.label,
        },
        destination: {
          coordinates: destination.coordinates,
          name: destination.name.label,
        },
        dateTime: finalDate,
        noOfPeople,
        carType,
        fare: fare != "" ? fare : null,
        clientId,
        guestName,
        guestPhoneNumber,
        flightNumber,
        nameCaller,
        paymentType,
        timeZone: "Europe/Amsterdam",
        bookingReference
      })
      .then((response: any) => {
        dispatch(Local.BookingsListing.api.request());
        dispatch(Local.CreateBooking.api.response({}));
        dispatch(Local.CreateBooking.resetAll());
        dispatch(Local.CreateBooking.api.reset());
        toastMessage("success",<><section>
          <h1 className="font-14 font-600">New Booking Create Successfully</h1>
          {/* <p className="font-12"><strong>RefId:</strong> {response?.data?.refId}</p> */}
          <p className="font-12">
        <strong>RefId:</strong>{" "}
        <span
          className="ref-id text-primary font-500"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            navigator.clipboard.writeText(response?.data?.refId || "");
          }}
        >
          {response?.data?.refId}
        </span>
      </p>
          </section></>)
        return true
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.BookingsListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        }
        return false
      });
  },

  DuplicateBooking: (id, obj) => async (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token }
    } = getState();
    const api = mobioApi(token);
    let destination = "";
    let pickUp = "";
    if (typeof (obj.pickup.name) == "object") {
      pickUp = obj.pickup.name.label;
    }
    else {
      pickUp = obj.pickup.name;
    }
    if (typeof (obj.destination.name) == "object") {
      destination = obj.destination.name.label;
    }
    else {
      destination = obj.destination.name;
    }

    let finalDate;
    let timeZone;
    if (typeof obj.dateTime == 'object' && typeof obj.pickup.name == "object") {
      timeZone = await GetTimeZone(obj?.pickup?.coordinates);
      const newDateTime = getTimeZoneTime(obj.dateTime, timeZone.timeZoneId)
      finalDate = new Date(newDateTime).toISOString()
    } else if (typeof obj.dateTime == 'object') {
      const newDateTime = getTimeZoneTime(obj.dateTime, obj.timeZone)
      finalDate = new Date(newDateTime).toISOString()
      timeZone = obj.timeZone
    } else if (typeof obj.pickup.name == "object") {
      timeZone = await GetTimeZone(obj?.pickup?.coordinates);
      const prevLocalTime = moment(obj.dateTime).tz(obj.timeZone);
      const conversion = prevLocalTime.clone().tz(timeZone.timeZoneId, true);
      const formatted = conversion.format();
      finalDate = new Date(formatted).toISOString()
    } else {
      finalDate = obj.dateTime;
      timeZone = obj.timeZone;
    }
    return api
      .createBooking({
        pickup: {
          coordinates: obj.pickup.coordinates,
          name: pickUp,
        },
        destination: {
          coordinates: obj.destination.coordinates,
          name: destination,
        },
        dateTime: finalDate,
        noOfPeople: obj.noOfPeople,
        carType: obj.carType,
        fare: obj.fare,
        clientId: obj.clientId,
        guestName: obj.guestName,
        guestPhoneNumber: obj.guestPhoneNumber,
        flightNumber: obj.flightNumber,
        nameCaller: obj.nameCaller,
        paymentType: obj.paymentType,
        duplicatedBookingId: id,
        timeZone: timeZone.timeZoneId ? timeZone.timeZoneId : timeZone,
        bookingReference: obj.bookingReference
      })
      .then((response: any) => {
        dispatch(Local.BookingsListing.api.request());
        return true
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.BookingsListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        }
        return false
      });
  },
  UpdateBooking: (id, obj) => async (dispatch: any, getState: any, mobioApi: any) => {

    const {
      userInfo: { token, user },
    } = getState();
    const api = mobioApi(token);
    const pickup = {
      pickup: {
        name:
          typeof obj.pickup.name == "string"
            ? obj.pickup.name
            : obj.pickup.name.label,
        coordinates: obj.pickup.coordinates,
      },
    };
    const destination = {
      destination: {
        name:
          typeof obj.destination.name == "string"
            ? obj.destination.name
            : obj.destination.name.label,
        coordinates: obj.destination.coordinates,
      },
    };

    let finalDate;
    let timeZone;
    if (typeof obj.dateTime == 'object' && typeof obj.pickup.name == "object") {
      timeZone = await GetTimeZone(pickup?.pickup?.coordinates);
      const newDateTime = getTimeZoneTime(obj.dateTime, timeZone.timeZoneId)
      finalDate = new Date(newDateTime).toISOString()
    } else if (typeof obj.dateTime == 'object') {
      const newDateTime = getTimeZoneTime(obj.dateTime, obj.timeZone)
      finalDate = new Date(newDateTime).toISOString()
      timeZone = obj.timeZone
    } else if (typeof obj.pickup.name == "object") {
      timeZone = await GetTimeZone(pickup?.pickup?.coordinates);
      const prevLocalTime = moment(obj.dateTime).tz(obj.timeZone);
      const conversion = prevLocalTime.clone().tz(timeZone.timeZoneId, true);
      const formatted = conversion.format();
      finalDate = new Date(formatted).toISOString()
    } else {
      finalDate = obj.dateTime;
      timeZone = obj.timeZone;
    }

    return api
      .updateBooking(id, {
        ...obj,
        ...destination,
        ...pickup,

        fare: obj?.fare != '' ? obj.fare : null,
        dateTime: finalDate,
        timeZone: timeZone?.timeZoneId || timeZone
      })
      .then((response: any) => {
        dispatch(Local.BookingsListing.api.request());
        // alert("Booking updated successful");
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.BookingsListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        }
      });
  },
  CancelBooking: (id, role, userId) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .cancelBooking({ id: id, role: role, userId: userId })
      .then((response: any) => {
        dispatch(Local.BookingsListing.api.request());
        // alert("Booking updated successful");

      })
      .then((response: any) => {
        dispatch(Local.BookingsListing.api.request());
        // alert("Booking updated successful");
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.BookingsListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.BookingsListing.api.error("Request Failed", data.msg));
        }
      });
  },
  CancelPendingBooking:
    (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .cancelPendingBooking(id)
        .then((response: any) => {
          dispatch(Local.BookingsListing.api.request());
          // alert("Booking updated successful");
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.BookingsListing.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(
              Local.BookingsListing.api.error("Request Failed", data.msg)
            );
          }
        });
    },
  getTotalAndAssignedRides: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getTotalAndAssignedRides()
      .then(async (response: any) => {
        let responseData = await GetInfoFromFlightaware(response.data);
        const data = _.chain(responseData)
          .groupBy((record) => moment(record.dateTime).format("YYYY-MM-DD"))
          .map((value, key) => {
            const remaining = value.filter(function (item) {
              return (item.dispatcherId == null && item.driverId == null && item.cancelled == false && item.rideEnd == false && moment(new Date()).subtract({ hours: 4 }) <= moment(item.dateTime));
            }).length;
            const completed = value.filter(function (item) {
              return ((item.dispatcherId != null || item.driverId != null) && item.cancelled == false && item.rideEnd == true);
            }).length;
            const cancelled = value.filter(function (item) {
              return item.cancelled == true
            }).length;
            return (
              {
                date: key,
                data: value,
                remaining: remaining,
                completed: completed,
                cancelled: cancelled
              }
            )
          })
          .value();
        dispatch(Local.TotalOverAssigned.response(data))
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.BookingsListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.TotalOverAssigned.error("Request Failed", data?.msg))

        }
      });
  },

};

export default ThunksBooking;
