import * as Yup from "yup";

export const networkValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Network Name is required")
    .min(2, "Network Name must be at least 2 characters")
    .max(100, "Network Name must be less than 100 characters"),

  country: Yup.string()
    .required("Country is required")
    .min(2, "Country must be at least 2 characters")
    .max(50, "Country must be less than 50 characters"),

  city: Yup.string()
    .required("City is required")
    .min(2, "City must be at least 2 characters")
    .max(50, "City must be less than 50 characters"),

  supportNumber: Yup.string()
    .required("Support Phone Number is required")
    .matches(/^[0-9]+$/, "Support Phone Number must be a number")
    .min(10, "Support Phone Number must be at least 10 digits")
    .max(15, "Support Phone Number must be less than 15 digits"),

  countryCode: Yup.string()
    .required("Country Code is required")
    .matches(
      /^\+[0-9]{1,4}$/,
      "Country Code must be a valid code, e.g., +1, +92"
    ),

  documents: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required("Document Name is required")
          .min(2, "Document Name must be at least 2 characters")
          .max(100, "Document Name must be less than 100 characters"),

        imageType: Yup.string()
          .optional()
          .oneOf(
            ["Single", "Multiple", "FrontBack", "FrontBackSide"],
            "Invalid Image Type selected"
          )
          .nullable(true)
          .transform((_, val) => val || null),

        pattern: Yup.string().optional().nullable(true),

        type: Yup.string()
          .required("Type is required")
          .oneOf(["Text", "File", "Image", "Pattern"], "Invalid Type selected"),

        mandatory: Yup.boolean().required("Mandatory field is required"),

        expiryIncluded: Yup.boolean().required("Expiry field is required"),
      })
    )
    .min(1, "At least one document is required"),
});
